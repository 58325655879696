@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');

@layer base {
  html {
    @apply bg-gray-900;
    font-size: 18px;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    /* font-size: 18px; */
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

p {
  white-space: pre-line;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

/* ------------------ NAVIGATION */

.nav {
  font-size: 1.2rem;
}


/* ------------------ HOME PAGE */

.nothing-font {
  font-family: "Nothing You Could Do", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.name-title {
  font-size: 5rem;
}

.contact-icon {
  display: inline-block;
}

/* #about-header {
  position: fixed;
  top: 86.4px;
  left: 50%;
  transform: translate(-50%, 0%);
} */

#about-header .svg-inline--fa {
  height: 2.2em;
}

#about-main {
  /* position: fixed;
  top: 366.7px;
  left: 50%;
  transform: translate(-50%, 0%); */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 60%;
}

#about-left {
  /* position: fixed;
  top: 366.7px;
  left: 50%;
  transform: translate(-50%, 0%); */
  grid-column: span 1 / span 1;
  margin-right: 15%;
}

#about-right {
  grid-column: span 1 / span 1;
  max-height: 60vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

#about-right::-webkit-scrollbar {
  display: none;
}

/* Maybe integrate this later with JS to hide on scroll? */
#scroll-down {
  position: fixed;
  top: 93%;
  display: none;
}

.exp-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: clamp(4px, calc(-5.09px + 2.42vw), 24px);
  padding-bottom: 30px;
}

.exp-left {
  grid-column: span 1 / span 1;
  padding-top: 7px;
  font-size: 0.9rem;
}

.exp-right {
  grid-column: span 3 / span 3;
  font-size: 0.9rem;
}

/* ------------------- MODAL */

.modal {
  position: fixed;
  height: 80%;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 0px; */
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior-block: contain;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

.modal button {
  position: sticky;
  /* right: 8px; */
  left: 97%;
  top: 0px;
  font-weight: 600;
  z-index: 101;
  color: #fff;
}

.overlayStyles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 100;
}